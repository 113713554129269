import { FRANCE_FR, PORTUGAL_PT, SPAIN_ES } from "@/config/app-constant";
import fetchPhoneNumberData from "@/hooks/magnolia/fetchPhoneNumber";
import { helpOverlayCallNumberBrandImpact } from "@/utils/help-overlay.utils";

export const useOriginCountryData = async (): Promise<string | null> => {
  try {
    const response = await fetch("/api/originCountry/");
    if (!response.ok) throw new Error("Failed to fetch origin country");

    const data = await response.json();
    return data.country;
  } catch (error) {
    console.error("Error fetching origin country:", error);
    return null;
  }
};

interface PhoneNumberEntry {
  desktop: string;
  mobile: string;
  name: string;
  default: string;
  "@path": string;
}

export const getPhoneNumberByCountry =
  async (): Promise<PhoneNumberEntry | null> => {
    try {
      // Fetch country name and phone numbers in parallel
      const [countryName, phoneNumbersResponse] = await Promise.all([
        helpOverlayCallNumberBrandImpact
          ? useOriginCountryData()
          : Promise.resolve(null),
        fetchPhoneNumberData(),
      ]);

      if (!phoneNumbersResponse?.results?.length) {
        console.warn("No phone numbers found.");
        return null;
      }

      const filteredPhoneNumbers = phoneNumbersResponse.results.filter(
        (phoneNumber) => phoneNumber["@path"].startsWith("/expat/help-overlay/")
      );

      const normalizedCountry = countryName?.trim().toLowerCase() ?? "";
      const validCountries = [SPAIN_ES, FRANCE_FR, PORTUGAL_PT].map((c) =>
        c.toLowerCase()
      );

      // Check if country is valid
      if (!validCountries.includes(normalizedCountry)) {
        console.warn(
          "Invalid or missing country name, returning default phone number."
        );

        return (
          filteredPhoneNumbers.find(
            (phoneNumber) => phoneNumber.name.trim().toLowerCase() === "default"
          ) ?? null
        );
      }

      // Find phone num matching the country
      return (
        filteredPhoneNumbers.find(
          (phoneNumber) =>
            phoneNumber.name.trim().toLowerCase() === normalizedCountry
        ) ?? null
      );
    } catch (error) {
      console.error("Error fetching phone number:", error);
      return null;
    }
  };
