"use client";

import React, { useEffect, useState } from "react";

import { ContactNumberProvider } from "@/hooks/contact-number-context/contact-number-context";
import fetchHelpOverlay from "@/hooks/magnolia/fetchHelpOverlay";

import BrandProvider from "./brand-provider";
import LoadingProvider from "./loading-provider";
import { NavigationProvider } from "./navigation-provider";
import { helpOverlayCallNumberBrandImpact } from "@/utils/help-overlay.utils";
import { getPhoneNumberByCountry } from "@/hooks/api/originCountryData/useOriginCountryData";

type HelpOverlayResponse = {
  ctaLabelOne?: string;
};

type PhoneNumberEntry = {
  desktop?: string;
  mobile?: string;
  name?: string;
  default?: string;
  "@path"?: string;
};

function AppProvider({ children }: { children: React.ReactNode }) {
  const [phoneCustomerService, setPhoneCustomerService] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (helpOverlayCallNumberBrandImpact) {
          let exp: PhoneNumberEntry;
          exp = (await getPhoneNumberByCountry()) ?? {};
          if (exp.default) {
            setPhoneCustomerService(exp.default);
          }
        } else {
          let pageJson: HelpOverlayResponse = {};
          pageJson = (await fetchHelpOverlay("highRisk")) ?? {};
          if (pageJson?.ctaLabelOne) {
            setPhoneCustomerService(pageJson.ctaLabelOne);
          }
        }
      } catch (error) {
        console.error("Error fetching help overlay:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <ContactNumberProvider phoneCustomerService={phoneCustomerService}>
      <LoadingProvider>
        <BrandProvider>
          <NavigationProvider>{children}</NavigationProvider>
        </BrandProvider>
      </LoadingProvider>
    </ContactNumberProvider>
  );
}

export default AppProvider;
