import axios from "axios";

import { MAGNOLIA_PHONE_NUMBER_URL } from "@/constants";
import logError from "@/utils/logger";

interface PhoneNumberEntry {
  name: string;
  default: string;
  desktop: string;
  mobile: string;
  "@path": string;

  CallingFromTheUK: string;
  CallingFromOutsideTheUK: string;
  CallingFromMexico: string;
  CallingFromUSAorCanada: string;
  NonEmergencyClaims: string;
  MedicalAssistanceInEurope: string;
  MedicalAssistanceWithinTheUSAandCanada: string;
}

interface PhoneNumberResponse {
  total: number;
  offset: number;
  limit: number;
  results: PhoneNumberEntry[];
}

const fetchPhoneNumberData = async (): Promise<PhoneNumberResponse> => {
  try {
    const response = await axios.get<PhoneNumberResponse>(
      MAGNOLIA_PHONE_NUMBER_URL
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      logError(error, { location: "fetchPhoneNumber.tsx" });
    } else {
      logError(new Error("An unknown error occurred"), {
        location: "fetchPhoneNumber.tsx",
      });
    }
    return { total: 0, offset: 0, limit: 0, results: [] };
  }
};

export default fetchPhoneNumberData;
