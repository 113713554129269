import { PRODUCT, SECONDARY_PRODUCT } from "@/constants";

const brandImpact = {
  staysure: false,
  avanti: false,
  expat: true,
};

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

export const helpOverlayCallNumberBrandImpact = brandImpact[brand];
