import { HELPOVERLAY_URL, PRODUCT, SECONDARY_PRODUCT } from "@/constants";
import axios from "axios";

const fetchHelpOverlay = async (
  risk: "lowRisk" | "mediumRisk" | "highRisk" | "veryHighRisk"
) => {
  let pageJson;
  await axios
    .get(`${HELPOVERLAY_URL}/${SECONDARY_PRODUCT ?? PRODUCT}/${risk}`)
    .then(async (res) => {
      pageJson = res.data;
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((e) => {
      pageJson = {};
    });
  return pageJson;
};

export default fetchHelpOverlay;
