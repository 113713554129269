"use-client";

import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useMutationObserver } from "./use-mutation-observer";

export const DEFAULT_CONTACT_NUMBER = "0800 033 4902";
const customerService = { contactNumber: DEFAULT_CONTACT_NUMBER };

export const ContactNumberContext = createContext({
  customerServicePhoneNumbers: customerService,
  active: {
    customerService: DEFAULT_CONTACT_NUMBER,
  },
});

export function ContactNumberProvider({
  children,
  phoneCustomerService,
}: Readonly<{
  children: ReactNode;
  phoneCustomerService: string;
}>) {
  const phoneNumbersMutationRef = useRef<HTMLSpanElement>(null);

  const [customerServicePhoneNumbers, setCustomerServicePhoneNumbers] =
    useState<{ contactNumber: string }>({
      contactNumber: phoneCustomerService ?? DEFAULT_CONTACT_NUMBER,
    });

  const [active, setActive] = useState({
    customerService: phoneCustomerService ?? DEFAULT_CONTACT_NUMBER,
  });

  const readPhoneNumbers = () => {
    if (phoneNumbersMutationRef.current) {
      setCustomerServicePhoneNumbers({
        contactNumber: phoneNumbersMutationRef.current.innerText,
      });
    }
  };

  useMutationObserver(phoneNumbersMutationRef, readPhoneNumbers);

  useEffect(() => {
    readPhoneNumbers();
  }, [phoneNumbersMutationRef]);

  useEffect(() => {
    setActive({
      customerService: customerServicePhoneNumbers.contactNumber,
    });
  }, [customerServicePhoneNumbers]);

  const contextValue = useMemo(
    () => ({ customerServicePhoneNumbers, active }),
    [customerServicePhoneNumbers, active]
  );

  return (
    <ContactNumberContext.Provider value={contextValue}>
      {children}
      <span
        id="test-span"
        ref={phoneNumbersMutationRef}
        className="hidden"
        hidden
      >
        {phoneCustomerService}
      </span>
    </ContactNumberContext.Provider>
  );
}
